html,
body,
div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Open Sans Light", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

.MuiAutocomplete-popper {
  z-index: 10000 !important;
}

.MuiInputAdornment-root {
  height: 1.5rem !important;
  color: #0594cd;
}

.MuiInputAdornment-positionStart {
  flex-shrink: 0;
  margin-right: 0px !important;
}

.MuiInputAdornment-positionEnd {
  min-width: 1.45rem;
}

.MuiTooltip-tooltipPlacementBottom {
  margin: 0 !important;
  max-width: 300px;
}

.MuiTooltip-popper {
  max-width: 300px;
}
